import React from 'react';
import {graphql} from "gatsby";
import Layout from "../../components/Layout/uk";
import {Container} from "@mui/material";
import Questions from "../../components/Questions/Questions";


function QuestionsUk({data, location}) {
  const questions = data.allMarkdownRemark.group[0]

  return (
    <Layout title='Відповіді на запитання по Typescript'
            description='Тут ти знайдеш відповіді на питання, які часто зустрічаються на співбесідах JS розробника'
            location={location}>
      <Container maxWidth="md">
        <div style={{textAlign: "center", padding: "15px 0"}}>
          <h1>Відповіді на запитання по Typescript</h1>
          <span>Тут ти знайдеш відповіді на питання, які часто зустрічаються на співбесідах</span>
        </div>
        <Questions questions={questions}/>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  {
    allMarkdownRemark(
      filter: {frontmatter: {templateKey: {eq: "questions"}, category: {eq: "TypeScript"}}, fields: {langKey: {eq: "uk"}}}
    ) {
      group(field: frontmatter___category) {
        edges {
          node {
            id
            frontmatter {
              question
              answer
              category
              code {
                code
              }
            }
          }
        }
        fieldValue
      }
    }
  }
`

export default QuestionsUk;